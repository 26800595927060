.button {
  height: 40px;
  display: flex;
  align-items: center;
  gap: 16px;
  border-radius: 80px;
  background: var(bg);
  border: none;
  padding: 0 16px 0 8px;
  text-align: left;
  color: var(--dark);
  transition: 0.25s color ease-in-out;


  &:not(.active):hover {
    cursor: pointer;
    color: var(--orange-main);
  }
}

.active {
  color: var(--orange-main);

  .dot {
    background-color: var(--white);
  }

  .dotInner {
    transform: scale(1);
  }
}

.disabled {
  pointer-events: none;
  color: var(--grey);
}

.dot {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--grey-separator);
  transition: 0.25s background-color ease-in-out;
}

.dotInner {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--orange-main);
  transform: scale(0);
  transition: 0.25s transform ease-in-out;
}

.title {
  flex: 1;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.iconsWrapper,
.iconInnerWrapper {
  display: flex;
  align-items: center;
  gap: 4px;
}

.separator {
  width: 1px;
  height: 12px;
  background-color: var(--grey-separator);
}
