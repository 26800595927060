@import "../../../../styles/media-mixin.scss";

.container {
  flex-shrink: 0;
  flex-basis: 288px;
  border-radius: 6px;
  background: var(--bg);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 8px;

  @include media("<tablet-lg") {
    flex-direction: row;
    gap: 12px;
    justify-content: center;
    flex-basis: unset;
    padding: 16px;
  }
}

.progressItemWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;

  @include media("<tablet-lg") {
    flex-direction: row;
    gap: 12px;
    align-items: center;
  }
}

.separator {
  position: relative;
  width: 56px;
  height: 16px;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 4px;
    height: 100%;
    transform: translateX(-50%);
    background-color: var(--grey-separator);
    border-radius: 4px;
  }

  @include media("<tablet-lg") {
    width: 12px;
    height: 4px;
    background-color: var(--grey-separator);
    border-radius: 4px;

    &::after {
      display: none;
    }
  }
}