@import "../../../../styles/media-mixin.scss";

.container {
  padding: 0 24px 40px;
  gap: 40px;
}

.button {
  @include media("<phone-lg") {
    margin-top: auto;
    margin-bottom: 56px;
  }
}