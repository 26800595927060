@import "../../styles/media-mixin.scss";

.container {
  position: relative;
  background-color: var(--white);
  max-width: 100%;
  max-height: 100%;
  border-radius: 16px 16px 0 0;
  padding: 20px 20px 32px 20px;
  color: var(--dark);
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
}

.closeIcon {
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
  color: var(--grey);
  z-index: 1;
}

.swiperWrapper {
  overflow: hidden;
  margin-top: 24px;
}

.controlsWrapper {
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button {
  width: 40px;
  height: 40px;
  border: 2px solid var(--orange-main);
  border-radius: 50%;
  color: var(--orange-main);
  padding: 0;

  &:hover {
    color: var(--orange-main) !important;

  }
}

.prevButton {
  svg {
    transform: rotate(180deg);
  }
}
