@import "../../../../../../styles/media-mixin.scss";

.container {
  gap: 16px;
}

.checkbox {
  padding: 24px 40px;
  gap: 40px;
  height: 96px;

  span {
    max-width: 704px;
  }

  @include media("<tablet") {
    height: auto;
  }
}