.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px;
  border-radius: 4px;
  border: 1px solid var(--grey-separator);
  background-color: var(--bg-secondary);
  padding: 16px;
  text-align: left;
  color: var(--dark);
  transition-property: color, background-color, border-color;
  will-change: color, background-color, border-color;
  transition-timing-function: ease-in-out;
  transition-duration: 0.25s;

  &:not(.active):hover {
    color: var(--orange-main-hover);
    border-color: var(--orange-main-hover);
    cursor: pointer;
  }
}

.active {
  color: var(--orange-main);
  background-color: var(--grey-separator);
}

.titleWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.title,
.percent {
  color: currentColor;
  font-size: 18px;
  font-weight: 600;
}

.industryTitle {
  color: var(--grey);
  font-size: 14px;
  font-weight: 400;
}

.percent {
  flex-shrink: 0;
  font-weight: 700;
}
