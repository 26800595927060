.container {
  padding: 16px;
  border-radius: 4px;
  border: 1px solid var(--grey-separator);
  text-decoration: none;
}

.title {
  color: var(--dark);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.dateWrapper {
  color: var(--grey);
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

.paymentInfoWrapper {
  margin-top: 24px;
  display: flex;
  align-items: center;
  gap: 16px;
}

.summary {
  color: var(--dark);
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
}

.paymentMethod {
  width: 48px;
  height: 24px;
}
