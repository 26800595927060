.container {
  position: relative;
  width: 100%;
  height: 80vh;
  background-color: var(--white);
  border-radius: 16px 16px 0 0;
  padding-top: 32px;
  display: flex;
  flex-direction: column;
}

.careerInfoWrapper {
  overflow: hidden;
  flex: 1;
}

.careerInfo {
  max-height: 100%;
  overflow-y: scroll;
  padding-top: 0;
}

.title {
  margin: 0 auto;
  padding: 0 24px;
  max-width: 500px;
  color: var(--dark);
  font-size: 24px;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
}

.closeIcon {
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--grey);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  z-index: 1;
  padding: 4px;
  background-color: var(--white);
}