@import "../../../../../../styles/media-mixin.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.title {
  color: var(--grey);
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  text-transform: uppercase;
}

.featuresWrapper {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(2, 1fr);

  @include media("<tablet-lg") {
    grid-template-columns: 1fr;

  }
}

.featureContainer {
  background-color: var(--bg);
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px;
  border-radius: 8px;
}

.featureTitle {
  color: var(--dark);
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  text-transform: uppercase;
}

.featureDescription {
  color: var(--grey);
  font-size: 14px;
  line-height: 22px;
}
