@import "../../../../../../styles/media-mixin";

.container {
  margin: 80px auto 0;
  max-width: var(--content-max-width);
}

.title {
  color: var(--grey);
  font-size: 18px;
  font-weight: 700;
  line-height: 32px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 32px;
  padding: 0 var(--horizontal-page-padding);

  &:after {
    content: "";
    flex: 1;
    height: 1px;
    background-color: var(--grey-separator);
  }
}

.benefitsWrapper {
  max-width: 100%;
  overflow: scroll;
  margin-top: 48px;
  display: flex;
  justify-content: space-between;
  gap: 32px;
  padding: 0 var(--horizontal-page-padding);

  @include media("<desktop-lg") {
    gap: 16px;
  }

  @include media("<tablet-lg") {
    justify-content: flex-start;
  }
}
