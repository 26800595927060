.container {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.inputsWrapper {
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.button {
  margin: 0 24px;
}

.loginFooter {
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  background-color: var(--grey-separator);
  padding: 0 24px;
}

.footerText {
  color: var(--dark);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}