.container {
  padding: 0 24px 40px;
  gap: 16px;
}

.button {
  margin-top: 24px;
}

.changePassword {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  width: max-content;
}