@import "../../../../../../styles/media-mixin.scss";

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  transition: 0.25s opacity ease-in-out, 0.25s transform ease-in-out;
  opacity: 0;
  transform: translateY(5px) scale(0.98);
  padding: 32px 0;
}

.show {
  opacity: 1;
  transform: translateY(0) scale(1);
}

.titleAndSalaryWrapper {
  display: flex;
  gap: 24px;
  justify-content: space-between;
  align-items: flex-start;

  @include media("<desktop-lg") {
    flex-direction: column;
    align-items: unset;
  }
}

.title {
  max-width: 500px;
  color: var(--dark);
  font-size: 40px;
  font-weight: 500;
  line-height: 54px;

  @include media("<tablet") {
    align-self: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 40px;
    text-align: center;
  }
}

.personalitiesWrapper {
  flex-shrink: 0;
  margin-top: 12px;
  display: flex;
  align-items: center;
  gap: 4px;

  @include media("<desktop-lg") {
    max-width: 100%;
    width: 100%;
    padding: 0 24px;
    overflow-x: auto;
  }

  @include media("<tablet") {
    margin-top: 16px;
  }
}

.caption {
  flex-shrink: 0;
}

.descriptionsWrapper {
  margin: 40px 0;
  max-width: 576px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include media("<tablet") {
    margin: 24px 0;
  }
}

.industryDescription {
  color: var(--dark);
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
}

.jobGroupDescription,
.careerDescription {
  color: var(--grey);
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

.titleAndSalaryWrapper,
.personalitiesWrapper,
.descriptionsWrapper,
.featuresWrapper {
  padding: 0 32px;

  @include media("<desktop-lg") {
    padding: 0 24px;
  }

  @include media("<tablet") {
    padding: 0 16px;
  }
}

.salariesWrapper {
  margin: 24px 16px 0;
}