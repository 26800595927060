@import "../../styles/media-mixin.scss";

.pageContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: calc(var(--header-height) + 48px);

  @include media("<desktop-lg") {
    padding-top: calc(var(--header-height) + 24px);
  }
}

.container {
  margin: 0 auto;
  max-width: var(--content-max-width);
  padding: 0 var(--horizontal-page-padding) var(--horizontal-page-padding);
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.imageWrapper {
  width: 700px;
  max-width: 100%;

  img {
    width: 100%;
    height: auto;
  }
}

.title {
  color: var(--dark);
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  text-transform: uppercase;

  @include media("<tablet") {
    font-size: 24px;
    line-height: 32px;
  }
}

.subtitle {
  margin-top: 16px;
  margin-bottom: 40px;
  color: var(--grey);
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}