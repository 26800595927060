@import "../../../../../../styles/media-mixin.scss";

.container {
  display: flex;
  align-items: center;
  gap: 16px;

  @include media("<tablet-lg") {
    width: 56px;
    flex-direction: column;
  }
}

.titleWrapper {
  position: relative;
  color: var(--grey);
  font-size: 18px;
  font-weight: 700;
  line-height: 32px;
  text-transform: uppercase;
  transition: 0.5s color ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;

  &.active {
    color: var(--dark);
  }

  &.completed {
    color: var(--green-main)
  }

  @include media("<tablet-lg") {
    max-width: 100%;
    height: 18px;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    text-transform: capitalize;
  }
}

.title {

  @include media("<tablet-lg") {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}
