@import "../../styles/media-mixin.scss";

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: var(--header-height);
  transition: 0.25s background-color ease-in-out;
  z-index: var(--header-z-index);
}

.withBackground {
  background-color: var(--bg);

  .innerWrapper {
    padding-bottom: 16px;
  }
}

.innerWrapper {
  height: 100%;
  margin: 0 auto;
  max-width: var(--content-max-width);
  display: flex;
  align-items: flex-end;
  gap: 24px;
  padding: 0 var(--horizontal-page-padding) 4px;
  transition: 0.25s padding-bottom ease-in-out;
}

.buttonsWrapper {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 8px;
}

.careerMatchesButton {
  padding: 0 8px 0 16px;
  gap: 16px;

  &:hover {
    .withCount {
      background-color: var(--green-main-hover);
    }
  }

  @include media("<tablet") {
    padding: 0 8px;
    & p:first-child {
      display: none;
    }
  }
}

.careerMatchesCount {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: var(--white);
  transition: 0.25s background-color ease-in-out;
}

.withCount {
  color: var(--white);
  background-color: var(--green-main);
}

.userProfileButton {
  padding: 0 8px 0 16px;
  gap: 16px;

  @include media("<tablet") {
    padding: 0 8px;
    & p:first-child {
      display: none;
    }
  }
}

.settingsIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: var(--bg);
  color: var(--grey);
}

.hideMobile {
  @include media("<tablet") {
    display: none !important;
  }
}

.modal {
  @include media("<phone-lg") {
    margin-bottom: 0 !important;
  }
}