@import "../../../../../../styles/media-mixin.scss";

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 154px 16px;
  transition: 0.25s opacity ease-in-out, 0.25s transform ease-in-out;
  opacity: 0;
  transform: translateY(5px) scale(0.98);

  @include media("<desktop-lg") {
    padding: 40px;
  }

  @include media("<tablet") {
    padding: 16px;
  }
}

.show {
  opacity: 1;
  transform: translateY(0) scale(1);
}

.textWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
}

.title {
  margin-bottom: 8px;
  color: var(--dark);
  font-size: 40px;
  font-weight: 500;
  line-height: 56px;
  text-transform: uppercase;
}

.subtitle {
  margin-bottom: 32px;
  color: var(--grey);
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
}

.imageWrapper {
  position: relative;
  margin-top: auto;
  width: 100%;
  max-width: 628px;

  img {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: auto;
    z-index: 0;
  }
}

