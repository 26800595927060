@import "../../../../styles/media-mixin.scss";

.wrapper {
  padding-top: calc(var(--header-height) + 80px);
  padding-bottom: 104px;
  flex: 1;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;

  @include media("<tablet") {
    padding-top: calc(var(--header-height) + 64px);
    padding-bottom: 80px;
  }
}

.innerWrapper {
  margin: 0 auto;
  max-width: var(--content-max-width);
  padding: 0 40px 0 var(--horizontal-page-padding);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas:
          "caption cards-with-captions"
          "title cards-with-captions"
          "videos cards-with-captions"
          "cardsWrapper cards-with-captions";

  @include media("<desktop-xlg") {
    padding: 0 var(--horizontal-page-padding);
  }

  @include media("<desktop") {
    grid-template-columns: 1fr;
    grid-template-areas:
            "caption"
            "title"
            "videos"
            "cards-with-captions"
            "cardsWrapper";
  }

  @include media("<tablet") {
    grid-template-areas:
            "caption"
            "title"
            "videos"
            "cardsWrapper"
            "cards-with-captions";
  }
}

.caption {
  grid-area: caption;

  @include media("<desktop") {
    justify-self: center;
  }
}

.title {
  grid-area: title;
  margin-top: 24px;
  color: var(--dark);
  font-size: 40px;
  line-height: 48px;
  font-weight: 500;
  max-width: 670px;

  @include media("<desktop") {
    justify-self: center;
    text-align: center;
    font-size: 40px;
    line-height: 48px;
  }

  @include media("<tablet") {
    font-size: 24px;
    line-height: 32px;

  }
}

.swiperWrapper {
  grid-area: videos;
  overflow: hidden;
  position: relative;

  @include media("<desktop") {
    max-width: 584px;
    justify-self: center;
  }

  @include media("<tablet") {
    max-width: 100%;
  }
}

.swiper {
  margin-top: 24px;
  overflow: unset !important;
  padding: 0 20px !important;

  @include media("<desktop") {
    padding: 0 56px !important;
  }

  @include media("<tablet") {
    padding: 0 !important;
  }
}

.videoImageWrapper {
  position: relative;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 268px;
  border-radius: 8px;
  background-position: center;
  background-size: cover;
  cursor: pointer;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.25);
    z-index: 1;
  }
}

.button {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  border: 2px solid var(--orange-main);
  border-radius: 50%;
  color: var(--orange-main);
  z-index: 100;
  padding: 0;
  background: var(--bg);

  &:hover {
    color: var(--orange-main) !important;

  }

  @include media("<tablet") {
    display: none;
  }
}

.prevButton {
  right: unset;
  left: 0;

  svg {
    transform: rotate(180deg);
  }
}

.description {
  grid-area: videos;
  max-width: 480px;
  margin-top: 40px;
  color: var(--grey);
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;

  @include media("<desktop") {
    justify-self: center;
    text-align: center;
  }

  @include media("<tablet") {
    font-size: 14px;
    line-height: 24px;
  }
}

.cardsWrapper {
  grid-area: cardsWrapper;
  margin-top: 80px;
  display: flex;
  gap: 16px;
}

.card {
  @include media("<desktop") {
    flex: 1;
    z-index: 1;
  }
}

.cardButton {
  flex: 1 1;
}

.cardsWithCaptions {
  grid-area: cards-with-captions;

  @include media("<desktop") {
    margin-top: 56px;
  }
}

.modal {
  @include media("<phone-lg") {
    margin-bottom: 0 !important;
  }
}

.videoModal {
  @include media("<tablet") {
    margin-bottom: 0 !important;
  }
}
