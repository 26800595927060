@import "../../../../../../styles/media-mixin";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  @include media("<desktop") {
    flex-direction: column-reverse
  }

  @include media("<tablet") {
    align-items: flex-start;
  }
}

.imagesWrapper {
  position: relative;
  width: 100%;
  height: 544px;

  @include media("<desktop-lg") {
    height: calc(544px * 0.8);
  }
  @include media("<desktop") {
    height: unset;
  }
}

.image {
  position: absolute;
  width: 296px;
  height: auto;
  display: block;
  border-radius: 12px;
  box-shadow: 0 40px 80px 0 rgba(7, 19, 59, 0.02), 0 40px 64px 0 rgba(7, 19, 59, 0.08), 0 0 120px 0 rgba(7, 19, 59, 0.02);
  animation-duration: 10s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;

  @include media("<desktop-lg") {
    width: calc(296px * 0.8);
  }

  @include media("<desktop") {
    width: 296px;
  }

  &:nth-child(1) {
    transform: rotate(-12deg);
    left: 32px;
    bottom: 130px;
    animation-name: firstCardAnimation;

    @include media("<desktop") {
      left: unset;
      right: 0;
      bottom: 0;
      transform: translate(60%, 50%) rotate(15deg);
    }

    @include media("<tablet") {
      display: none;
    }

  }

  &:nth-child(2) {
    transform: rotate(10deg) translateX(-50%);
    left: 50%;
    top: 48px;
    animation-name: secondCardAnimation;

    @include media("<desktop") {
      display: none;
    }
  }

  &:nth-child(3) {
    transform: rotate(20deg);
    bottom: 36px;
    right: 56px;
    animation-name: thirdCardAnimation;

    @include media("<desktop") {
      right: unset;
      left: 0;
      bottom: 0;
      transform: translate(-60%, 50%) rotate(-15deg);
    }

    @include media("<tablet") {
      left: unset;
      right: 0;
      bottom: 0;
      transform: translate(60%, 20%) rotate(-15deg);
    }
  }

  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(3) {
    @include media("<desktop") {
      animation-name: unset;
      z-index: 0;
    }
  }
}

.captionsWrapper {
  display: flex;
  gap: 16px;

  @include media("<tablet") {
    flex-direction: column;
    gap: 30px;
  }
}

.captionWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: 250px;

  @include media("<tablet") {
    align-items: flex-start;
  }
}

.captionTitle {
  color: var(--dark);
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  line-height: 56px;

  @include media("<tablet") {
    text-align: left;
  }
}

.captionDescription {
  color: var(--grey);
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;

  @include media("<tablet") {
    text-align: left;
  }
}

@keyframes firstCardAnimation {
  0%, 100% {
    transform: rotate(-12deg);
  }
  70% {
    transform: rotate(-12deg) translate(-12px, -32px);
  }
}

@keyframes secondCardAnimation {
  0%, 100% {
    transform: rotate(10deg) translateX(-50%);
  }
  70% {
    transform: rotate(20deg) translate(-45%, -12px);
  }
}

@keyframes thirdCardAnimation {
  0%, 100% {
    transform: rotate(20deg);
  }
  70% {
    transform: rotate(4deg) translate(30px, -30px);
  }
}


