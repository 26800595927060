@import "../../../../../../../styles/media-mixin";

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include media("<desktop-lg") {
    align-items: center;
  }

  @include media("<tablet-lg") {
    align-items: flex-start;
    flex-shrink: 0;
  }
}

.image {
  height: 96px;
  width: auto;

  @include media("<tablet-lg") {
    height: 80px;
  }
}

.title {
  margin-top: 24px;
  color: var(--dark);
  font-size: 24px;
  font-weight: 500;
  line-height: 40px;

  @include media("<tablet-lg") {
    font-size: 18px;
    line-height: 32px;
  }
}

.description {
  margin-top: 8px;
  color: var(--grey);
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  max-width: 390px;

  @include media("<desktop-lg") {
    text-align: center;
  }

  @include media("<tablet-lg") {
    text-align: left;
    font-size: 14px;
    line-height: 24px;
    max-width: 294px;
  }
}
