@import "../../../../../../styles/media-mixin.scss";

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1;
  transition: 0.25s opacity ease-in-out, 0.25s transform ease-in-out;
  opacity: 0;
  transform: translateY(5px) scale(0.98);
  padding-top: 24px;
  padding-left: 40px;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 46px;
    opacity: 0.04;
    background: linear-gradient(0deg, var(--dark) 0%, rgba(7, 19, 59, 0.00) 100%);
    z-index: 2;
    pointer-events: none;
  }

  @include media("<tablet-lg") {
    &:after {
      display: none;
    }
  }

  @include media("<tablet") {
    padding: 16px 16px 0;
    gap: 16px;
  }
}

.show {
  opacity: 1;
  transform: translateY(0) scale(1);
}

.titleWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;

  @include media("<tablet") {
    align-items: center;
    text-align: center;
  }
}

.titleInnerWrapper {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  max-width: 646px;

  @include media("<tablet") {
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
}

.title {
  color: var(--dark);
  font-size: 24px;
  font-weight: 700;
  line-height: 40px;

  @include media("<tablet") {
    font-size: 18px;
    line-height: 28px;
  }
}

.separator {
  width: 100%;
  height: 1px;
  background-color: var(--bg);
}

.innerContainer {
  position: relative;
  flex: 1;
  overflow: hidden;

  @include media("<tablet-lg") {
    overflow: visible;
  }
}

.innerWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding: 2px 40px 48px 2px;

  @include media("<tablet-lg") {
    position: relative;
  }

  @include media("<tablet") {
    position: relative;
    padding: 2px 2px 48px 2px;
  }
}