@import "../../../../styles/media-mixin.scss";

.personalityWrapper {
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--bg);
  flex-shrink: 0;


  @include media("<phone-lg") {
    font-size: 14px;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
  }
}

.title {
  color: var(--green-main);
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;
  background-color: var(--bg);
  padding: 8px 24px 8px 16px;


}

.description {
  color: var(--grey);
  font-weight: 500;
  line-height: 24px;
  padding: 8px 24px 8px 16px;
}
