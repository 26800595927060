@import "../../../../../../styles/media-mixin.scss";

.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 80px;
  grid-gap: 16px;

  @include media("<desktop-lg") {
    grid-template-columns: 1fr;
    grid-auto-rows: unset;
  }
}
