@import "../../../../styles/media-mixin.scss";

.container {
  margin: 0 auto;
  padding: 104px var(--horizontal-page-padding);
  display: flex;
  background-color: var(--bg);
  gap: 24px;
  justify-content: space-between;
  width: var(--content-max-width);
  max-width: 100%;
  overflow: hidden;

  @include media("<desktop-lg") {
    padding: 104px 0;
    flex-direction: column;
    gap: 40px;
  }

  @include media("<tablet") {
    padding: 80px 0;
  }
}

.infoWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;

  @include media("<desktop-lg") {
    padding: 0 var(--horizontal-page-padding);
  }

  @include media("<tablet") {
    gap: 16px;
  }
}

.caption {
  color: var(--grey);
  font-size: 18px;
  font-weight: 700;
  line-height: 32px;
  text-transform: uppercase;
}

.title {
  color: var(--dark);
  font-size: 40px;
  font-weight: 500;
  line-height: 56px;
  text-transform: uppercase;
  max-width: 550px;

  @include media("<tablet") {
    margin-top: 8px;
    font-size: 32px;
    line-height: 48px;
    max-width: 328px;
  }
}

.description {
  color: var(--grey);
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  max-width: 546px;
}

.actionsWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  //margin-top: auto;

  @include media("<desktop-lg") {
    display: none;
  }
}

.prevButton,
.nextButton {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  color: var(--white);
  background-color: var(--orange-main);
  transition: 0.25s background-color ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: var(--orange-main-hover);
  }
}

.prevButton {
  transform: rotate(180deg);
}


.reviewsWrapper {
  display: flex;
  flex-shrink: 0;

  @include media("<desktop-lg") {
    //flex: 1;
    padding: 0 var(--horizontal-page-padding);
    overflow-x: scroll;
    gap: 16px;
    max-width: 100%;
  }

  @include media("<tablet") {
    gap: 12px;
  }
}

.hide {
  opacity: 0;
  transform: translateY(2%) scale(0.99);
}