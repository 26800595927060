@import "../../styles/media-mixin.scss";

.container {
  position: relative;
  width: 702px;
  height: 488px;
  max-width: 100%;
  max-height: 100%;
  background-color: var(--white);
  border-radius: 8px;
  transition: 0.25s height ease-in-out;
  display: flex;
  flex-direction: column;

  @include media("<phone-lg") {
    height: 724px;
  }
}

.PAYMENT {
  height: 594px;

  @include media("<phone-lg") {
    height: unset;
  }
}

.closeIcon {
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
  color: var(--grey);
  z-index: 1;
}

.preloader {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}
