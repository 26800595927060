.container {
  gap: 24px;
}

.optionWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.separator {
  height: 1px;
  width: 100%;
  background-color: var(--bg);
}
