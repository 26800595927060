.container {
  display: flex;
  flex-direction: column;
  padding: 32px;
  border-radius: 12px;
  transition: 0.25s background-color ease-in-out;
  color: var(--white);
  text-decoration: none;
  cursor: pointer;

  &:hover {
    .arrowIcon {
      transform: translateX(4px);
    }
  }
}

.caption {
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;
}

.title {
  margin-top: 8px;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
}

.actionTitle {
  margin-top: auto;
  padding-top: 42px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.arrowIcon {
  transition: 0.25s transform ease-in-out;
}

.green {
  background-color: var(--green-main);

  &:hover {
    background-color: var(--green-main-hover);
  }
}

.orange {
  background-color: var(--orange-main);

  &:hover {
    background-color: var(--orange-main-hover);
  }
}
