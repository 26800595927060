.careerWrapper {
  display: flex;
  gap: 4px;
  height: 32px;
  border-radius: 30px;
  border: 1px solid var(--grey-separator);
  padding-left: 12px;
  flex-shrink: 0;
}

.title {
  display: flex;
  align-items: center;
  color: var(--grey);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.percent {
  height: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 40px;
  background: rgba(253, 110, 3, 0.08);
  padding: 0 8px;
  color: var(--orange-main);
  font-weight: 500;
}
