@import "../../styles/media-mixin.scss";

.pageContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: calc(var(--header-height) + 80px);

  @include media("<desktop-lg") {
    padding-top: calc(var(--header-height) + 64px);
  }

  @include media("<tablet") {
    padding-top: calc(var(--header-height) + 56px);
  }
}

.container {
  margin: 0 auto;
  max-width: var(--content-max-width);
  padding: 0 var(--horizontal-page-padding) var(--horizontal-page-padding);
  flex: 1;
  width: 100%;
}

.mainSection {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 48px;

  @include media("<desktop-lg") {
    gap: 32px;
  }
}

.title {
  color: var(--dark);
  font-size: 56px;
  font-weight: 500;
  line-height: 72px;

  @include media("<desktop-lg") {
    font-size: 48px;
    line-height: 64px;
  }

  @include media("<tablet") {
    font-size: 32px;
    line-height: 48px;
    text-align: center;
  }
}

.cardsWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.emptyText {
  font-size: 24px;
  font-weight: 500;
  color: var(--grey);
}