@import "../../../../styles/media-mixin.scss";

.container {
  padding: 0 24px 40px;
  gap: 40px;
}

.actionsWrapper {
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 8px;

  @include media("<phone-lg") {
    margin-top: auto;
    margin-bottom: 56px;
  }
}