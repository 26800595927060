@import "../../../../../../styles/media-mixin.scss";

.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 80px;
  grid-gap: 16px;

  @include media("<tablet-lg") {
    grid-template-columns: 1fr;
  }
}

.subtitleWrapper {
  display: flex;
  align-items: center;
  gap: 12px;
  color: var(--grey);
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

.countWrapper {
  width: 56px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background: var(--bg);
  font-weight: 700;
}
