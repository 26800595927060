@import "fonts.scss";
@import "colors.scss";
@import "media-mixin.scss";
@import "variables.scss";
@import "z-index.scss";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  min-width: 0;
}

img {
  display: block;
}

html {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

body {
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg);
  position: relative;
  display: flex;
  flex-grow: 1;
  overflow-y: scroll;
  cursor: default;

  ::placeholder,
  button,
  input,
  textarea {
    font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
    font-style: normal;
  }

  &::-webkit-scrollbar,
  ::-webkit-scrollbar {
    width: var(--scroll-width);
    height: var(--scroll-width);

    &-track {
      background: transparent;
    }

    &-thumb {
      background: var(--grey-separator);
      border-radius: 2px;
      transition: 0.25s background ease-in-out;
    }
  }
}

.modalOpen {
  overflow: hidden;
}

#root {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.hiddenScroll {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */

  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
}

.pulse-animation {
  animation-iteration-count: 3;
  animation-duration: 3s;
  animation-name: pulse;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 rgba(0, 88, 0, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 88, 0, 0);
  }
  100% {
    transform: scale(1);
  }
}