.container,
.containerInner {
  gap: 32px;
}

.containerInner {
  padding: 0 24px;
}

.text {
  max-width: 400px;
}

.bottomText {
  border-top: 1px solid var(--grey-separator);
  padding: 16px;
}

.text,
.bottomText {
  color: var(--grey);
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
}
