@import "../../../../styles/media-mixin.scss";

.container {
  padding: 24px 40px;
  border-top: 1px solid var(--bg);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;

  @include media("<tablet") {
    padding: 16px;
  }
}

.button,
.buttonGreen {
  gap: 16px;
}

.buttonGreen {

}

.button {
  color: var(--dark);

  @include media("<tablet") {
    width: 48px;
    height: 48px;
    padding: 0;
    border-radius: 50%;
    span {
      display: none;
    }
  }
}

.icon {
  transform: scaleX(-1);
}