@import "../../styles/media-mixin.scss";

.container {
  position: relative;
  width: 480px;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  border-radius: 8px;
  padding-top: 40px;
  transition: 0.25s height ease-in-out;

  @include media("<phone-lg") {
    border-radius: 8px 8px 0 0;
  }
}

.LOGIN {
  height: 482px;
}

.LOG_OUT {
  height: 280px;
}

.REGISTRATION {
  height: 432px;
}

.FORGOT_PASSWORD {
  height: 304px;
}

.FORGOT_PASSWORD_MESSAGE {
  height: 418px;

  @include media("<phone-lg") {
    height: 442px;
  }
}

.INVALID_TOKEN_MESSAGE {
  height: 532px;
}

.PERSONAL {
  height: 578px;
}

.CHANGE_PASSWORD {
  height: 496px;
}

.SUCCESS {
  height: 475px;

  @include media("<phone-lg") {
    height: 500px;
  }
}

.LOGIN,
.LOG_OUT,
.REGISTRATION,
.FORGOT_PASSWORD {
  @include media("<phone-lg") {
    height: 480px;
  }
}

.closeIcon {
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
  color: var(--grey);
}

.goBackIcon {
  transform: scaleX(-1);
  position: absolute;
  top: 24px;
  left: 24px;
  cursor: pointer;
  color: var(--grey);
}
