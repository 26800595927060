@import "../../../../styles/media-mixin.scss";

.container {
  display: flex;
  flex: 1;
  transition: 0.25s opacity ease-in-out, 0.25s transform ease-in-out;
  opacity: 0;
  transform: translateY(5px) scale(0.98);
  overflow: auto;

  @include media("<phone-lg") {
    flex-direction: column;
    padding-top: 40px;
    gap: 24px;
  }
}

.show {
  opacity: 1;
  transform: translateY(0) scale(1);
}

.title {
  color: var(--dark);
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 40px;
  text-transform: uppercase;
  padding: 0 24px;

}

.subtitle {
  padding: 40px 24px;
  color: var(--dark);
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;

  @include media("<phone-lg") {
    padding: 24px 0;
    max-width: 224px;
  }
}

.img {
  width: 100%;
  height: auto;
}

.imgMobile {
  height: 114px;
  width: auto;
  align-self: flex-end;
  flex-shrink: 0;
}

.asideWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
  width: 222px;
  height: 100%;
  background-color: var(--bg);

  @include media("<phone-lg") {
    padding: 0 24px;
    flex-direction: row;
    justify-content: space-around;
    height: auto;
    width: auto;
    gap: 24px;
  }
}

.contentWrapper {
  padding-top: 40px;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 24px;

  @include media("<phone-lg") {
    padding: 0;
  }
}

.contentInnerWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
}
