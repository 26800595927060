@import "../../../../../../styles/media-mixin.scss";

.container {
  border-radius: 12px;
  background: var(--white);
  min-height: 400px;
  display: flex;
  flex-direction: column;
  will-change: opacity, transform;
  opacity: 0;
  transform: translateY(1%) scale(0.995);
  transition: 0.25s opacity ease-in-out, 0.25s transform ease-in-out;

  @include media("<desktop-lg") {
    flex: 1 0 680px;
    min-height: unset;
  }

  @include media("<tablet") {
    flex-basis: 280px;
  }
}

.show {
  opacity: 1;
  transform: translateY(0) scale(1);
}

.contentWrapper {
  display: flex;
  justify-content: space-between;
  padding: 32px;
  flex: 1;

  @include media("<tablet") {
    padding: 16px;
  }
}

.textWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.title {
  color: var(--dark);
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  max-width: 400px;

  @include media("<tablet") {
    font-size: 18px;
  }
}

.description {
  color: var(--grey);
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  max-width: 328px;

  @include media("<tablet") {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
  }
}

.avatar {
  height: 200px;
  width: auto;
  flex-shrink: 0;

  @include media("<tablet") {
    display: none;
  }
}

.avatarMobile {
  display: none;

  @include media("<tablet") {
    display: block;
    height: 36px;
    width: auto;
    flex-shrink: 0;
  }
}

.authorWrapper {
  padding: 16px 16px 16px 32px;
  border-top: 1px solid var(--grey-separator);
  display: flex;
  align-items: center;
  gap: 16px;

  @include media("<tablet") {
    padding: 12px 16px;
    gap: 12px;
  }
}

.authorName {
  color: var(--dark);
  font-size: 18px;
  font-weight: 700;
  line-height: 32px;
}

.authorProfession {
  padding: 6px 12px;
  border-radius: 40px;
  background: var(--bg);
  color: var(--orange-main);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;

  @include media("<tablet") {
    margin-left: auto;
  }
}

.icon {
  margin-left: auto;

  @include media("<tablet") {
    display: none;
  }
}
