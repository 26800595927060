@import "media-mixin.scss";

:root {
  --popover-shadow: 4px 4px 20px rgba(0, 0, 0, 0.2);
  --scroll-width: 4px;
  --horizontal-page-padding: 104px;
  --content-max-width: 1440px;
  --grid-gap-desktop: 20px;
  --grid-gap-mobile: 16px;
  --header-height: 80px;

  @include media("<desktop-xlg") {
    --horizontal-page-padding: 24px;
  }

  @include media("<tablet") {
    --horizontal-page-padding: 8px;
  }
}
