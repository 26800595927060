.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.title {
  color: var(--grey);
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  text-transform: uppercase;
}

.careersWrapper {
  display: flex;
  flex-direction: column;
  gap: 6px;
  //max-height: 500px;
  //overflow-y: auto;
  padding-right: 12px;
}
