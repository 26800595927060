@import "../../../styles/media-mixin.scss";

.optionWrapper {
  position: relative;
  display: flex;
  gap: 24px;
  align-items: center;
  padding: 16px 16px 16px 24px;
  border-radius: 4px;
  background-color: var(--bg);
  transition: 0.25s background-color ease-in-out, 0.25s outline-color ease-in-out;
  cursor: pointer;
  outline: 2px solid transparent;

  &:focus-visible {
    outline: 2px solid var(--orange-main);

  }

  &:not(.disabled):hover {
    background-color: var(--grey-separator);

    .checkbox {
      border-color: var(--orange-main);
    }

    .icon {
      color: var(--bg);
    }

    .title {
      color: var(--orange-main);
    }
  }

  @include media("<tablet") {
    padding: 12px 16px;
  }
}

.disabled {
  pointer-events: none;
  cursor: default;

  .checkbox {
    border-color: var(--grey);
  }

  .icon,
  .title {
    color: var(--grey) !important;
  }
}

.originCheckbox {
  flex-shrink: 0;
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
}

.checkbox {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid var(--grey);
  background: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.25s border-color ease-in-out;
}

.checkboxIcon {
  width: 10px;
  transition-duration: 0.25s;
  transition-timing-function: ease-in-out;
  transition-property: opacity, transform;
  opacity: 0;
  transform: scale(0);
  color: var(--orange-main);
}

.title {
  color: var(--dark);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  transition: 0.25s color ease-in-out;

  @include media("<tablet") {
    font-size: 14px;
    line-height: 22px;
  }
}

.icon {
  color: var(--grey-separator);
  margin-left: auto;
  flex-shrink: 0;
  transition: 0.25s color ease-in-out;
  width: 48px;
  height: 48px;

  svg {
    max-width: 100%;
    max-height: 100%;
  }

  @include media("<tablet") {
    display: none;
  }
}

.checked {
  .checkbox {
    border-color: var(--orange-main);
  }

  .checkboxIcon {
    opacity: 1;
    transform: scale(1);
  }

  .icon,
  .title {
    color: var(--orange-main) !important;
  }
}