.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.separator {
  width: 4px;
  height: 16px;
  border-radius: 4px;
  background-color: var(--grey-separator);
}

.circleContainer {
  position: relative;
}

.circleBackground {
  stroke: var(--grey-separator);
  transition: 0.5s all ease-in-out;
}

.circleProgress {
  stroke: var(--green-main);
  stroke-linecap: round;
  stroke-linejoin: round;
  transition: 0.5s all ease-in-out;
}

.circleInactive {
  border-radius: 50%;
  border-color: var(--grey-separator);
  border-style: solid;
}

.circleText {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0);
  color: var(--grey);
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 32px;
  text-transform: uppercase;
  transition: 0.5s color ease-in-out, 0.5s transform ease-in-out;
}

.activeText {
  color: var(--green-main);
}

.completedIcon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: 0.25s transform ease-in-out;
  color: var(--green-main);
}

.show {
  transform: translate(-50%, -50%) scale(1);
}

