@import "../../styles/media-mixin.scss";

.container {
  display: flex;
  flex-direction: column;
}

.modal {
  @include media("<phone-lg") {
    margin-bottom: 0 !important;
  }
}