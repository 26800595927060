@import "../../../../styles/media-mixin.scss";

.container,
.personalitiesWrapper,
.personalitiesInnerWrapper {
  display: flex;
  align-items: center;
}

.container {
  border-radius: 8px;
  background-color: var(--white);
  padding: 28px;
  justify-content: space-between;
  gap: 24px;

  @include media("<tablet") {
    background-color: transparent;
    padding: 0;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
}

.title {
  color: var(--dark);
  font-size: 24px;
  font-weight: 700;
  line-height: 40px;
  text-transform: uppercase;
}

.personalitiesWrapper {
  gap: 40px;
  flex-shrink: 0;

  @include media("<tablet") {
    max-width: 100%;
    overflow-x: scroll;
  }
}

.personalitiesTitle {
  color: var(--grey);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;

  @include media("<desktop-lg") {
    display: none;
  }
}

.personalitiesInnerWrapper {
  flex-shrink: 0;
  gap: 4px;
}
