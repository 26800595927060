@import "../../../../styles/media-mixin.scss";

.container {
  padding: 24px 0;
  border-radius: 8px;
  background: var(--white);
  display: flex;
  flex-direction: column;
  gap: 16px;
  border: 2px solid transparent;
  transition-property: border-color, background-color;
  transition-timing-function: ease-in-out;
  transition-duration: 0.25s;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    border-color: var(--orange-main-hover);
  }
}

.titleSection {
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

}

.titleAndDateWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.title {
  grid-area: title;
  color: var(--dark);
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  text-transform: uppercase;

  @include media("<desktop-lg") {
    font-size: 18px;
  }
}

.percent {
  grid-area: percent;
  flex-shrink: 0;
  padding: 4px 8px;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;

  @include media("<phone-lg") {
    margin-left: auto;
  }
}

.new {
  grid-area: new;
  flex-shrink: 0;
  padding: 4px 12px;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  background-color: var(--green-main);
  color: var(--white);
}

.date {
  flex-shrink: 0;
  grid-area: date;
  color: var(--grey);
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}

.personalitiesSection,
.careersSection {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.sectionTitle {
  color: var(--dark);
  font-weight: 600;
  line-height: 24px;
  padding: 0 24px;
}

.personalitiesInnerWrapper {
  padding: 0 24px;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.separator {
  height: 1px;
  background-color: var(--grey-separator);
  width: calc(100% - 24px * 2);
  margin: 0 auto;

  @include media("<tablet") {
    width: calc(100% - 16px * 2);
  }
}

.careersInnerWrapper {
  padding: 0 24px;
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;

  @include media("<phone-lg") {
    overflow: scroll;
    flex-wrap: nowrap;
  }
}

.showMore {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  gap: 12px;
  background: rgba(253, 110, 3, 0.08);
  padding: 0 12px;
  color: var(--orange-main);
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;

  &.desktop {
    height: 32px;
    border-radius: 30px;

    @include media("<phone-lg") {
      display: none;
    }
  }

  &.mobile {
    display: none;
    margin: 8px 16px 0;
    padding: 0 16px;
    height: 40px;
    border-radius: 4px;
    justify-content: space-between;

    @include media("<phone-lg") {
      display: flex;
    }
  }
}

.titleSection,
.sectionTitle,
.personalitiesInnerWrapper,
.careersInnerWrapper {
  @include media("<tablet") {
    padding: 0 16px;
  }
}



