.inputWrapper {
  position: relative;
  display: flex;
  height: 56px;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--grey-separator);
  background: var(--white);
  transition: 0.25s border-color ease-in-out, 0.25s background-color ease-in-out;

  &:not(.disableHover):hover {
    .iconWrapper {
      color: var(--green-main);
    }

    .input {
      &::placeholder {
        color: var(--green-main);
      }
    }
  }
}

.fullWidth {
  width: 100%;
}

.disabled {
  background-color: var(--bg);

  .input {
    color: var(--grey);
  }
}

.iconWrapper {
  position: absolute;
  left: 24px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--grey-separator);
  transition: 0.25s color ease-in-out;
}

.input {
  padding-left: 24px;
  transition: 0.25s color ease-in-out;
  color: var(--dark);
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  border: none;
  height: 100%;
  width: 100%;
  outline: none;
  border-radius: 4px;

  &::placeholder {
    transition: 0.25s color ease-in-out;
    color: var(--grey);
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
  }
}

.withIcon {
  padding-left: 58px;
}

.errorText {
  position: absolute;
  left: 24px;
  bottom: -16px;
  display: flex;
  align-items: center;
  height: 0;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  border-radius: 4px;
  color: var(--red-main);
  background-color: var(--red-hover);
  transition: 0.25s height ease-in-out, 0.25s opacity ease-in-out, 0.25s padding ease-in-out;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
}

.active {
  .iconWrapper {
    color: var(--orange-main);
  }
}

.error {
  border-color: var(--red-main);

  .iconWrapper {
    color: var(--red-main);
  }

  .errorText {
    height: 24px;
    opacity: 1;
    padding: 0 8px;
  }
}

.showHideIconWrapper {
  position: relative;
  margin-right: 24px;
  height: 100%;
  width: 24px;
  cursor: pointer;
  color: var(--grey);
}

.showHideIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  transform: translate(-50%, -50%) scale(0.5);
  opacity: 0;
  transition-property: opacity, transform;
  transition-duration: 0.25s;
  transition-timing-function: ease-in-out;
}

.show {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
}
