@import "../../../../styles/media-mixin.scss";

.container {
  align-items: center;
  gap: 40px;
  padding: 0 24px 40px;

  @include media("<phone-lg") {
    justify-content: space-evenly;
  }
}

.text {
  max-width: 324px;
  text-align: center;
  color: var(--grey);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.button {
  margin-top: auto;

  @include media("<phone-lg") {
    margin-top: 0;
  }
}