@import "../../../styles/media-mixin.scss";

.container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 24px;

  @include media("<desktop-lg") {
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
}

.title {
  color: var(--dark);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  .caption {
    display: none;
  }

  @include media("<desktop-lg") {
    align-self: stretch;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 24px;
    text-align: center;

    .caption {
      display: block;
    }
  }

  @include media("<tablet") {

    .caption {
      display: none;
    }
  }
}

.formWrapper {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include media("<desktop-lg") {
    align-self: stretch;
    flex-direction: row;

    .infoCaptionsWrapper {
      display: none;
    }
  }
}

.infoCaptionsWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.captionMobile {
  display: none;

  @include media("<tablet") {
    display: flex;
    align-self: stretch;
  }
}

.caption {
  flex-shrink: 0;
  color: var(--grey);
  font-size: 12px;
  line-height: 16px;
}

.formInnerWrapper {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  padding: 4px 6px;
  background-color: var(--bg);
  border-radius: 18px;
  width: max-content;
  outline: 2px solid transparent;
  transition: 0.25s outline-color ease-in-out;

  &:focus {
    outline-color: var(--orange-main);
  }

  @include media("<desktop-lg") {
    flex: 1;
    justify-content: space-between;
  }

}

.inputWrapper {
  position: relative;
  width: 64px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  @include media("<desktop-lg") {
    width: 20%;
  }
}

.checked {

  .valueWrapper {
    width: 100%;
    height: 100%;
    background-color: var(--orange-main);
    border-color: var(--orange-main);
    border-radius: 12px;
  }

  .value {
    opacity: 1;
    transform: scale(1);
  }
}

.valueWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: var(--white);
  border: 1px solid var(--grey-separator);
  will-change: width, height, border-color, background-color, border-radius;
  transition-property: width, height, border-color, background-color, border-radius;
  transition-timing-function: ease-in-out;
  transition-duration: 0.25s;
  cursor: pointer;

  &:hover {
    border-color: var(--orange-main);
  }
}

.value {
  will-change: opacity, transform;
  transition: 0.25s opacity ease-in-out, 0.25s transform ease-in-out;
  opacity: 0;
  transform: scale(0);
  color: var(--white);
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  text-transform: uppercase;
}

.radioOrigin {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  opacity: 0;
}
