@import "../../../../styles/media-mixin.scss";

.mainWrapper {
  flex: 1;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.forgotPassword {
  margin-top: 8px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  width: max-content;
}

.button {
  margin: 24px 0 40px;

  @include media("<phone-lg") {
    margin-top: auto;
  }
}

.loginFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  background-color: var(--bg);
  padding: 0 24px;
}

.footerText {
  color: var(--dark);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
