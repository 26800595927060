.container {
  padding: 0 24px 24px;
  gap: 24px;
}

.priceBlockWrapper {
  display: flex;
  justify-content: space-between;
  gap: 40px;
}

.priceTitle {
  color: var(--dark);
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
}

.priceWrapper {
  display: flex;
  gap: 8px;
}

.currentPriceWrapper {
  color: var(--dark);
  font-size: 24px;
  font-weight: 700;
  line-height: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  transition: 0.25s color ease-in-out;


}

.currentPrice {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    height: 2px;
    top: 50%;
    left: 0;
    right: 100%;
    transform: translateY(-50%);
    background-color: var(--grey);
    transition: 0.25s ease-in-out right;
  }
}

.nonActual {
  color: var(--grey);

  &:after {
    right: 0;
  }
}

.discountResultWrapper {
  height: 40px;
}

.discountResultInnerWrapper {
  height: 100%;
  transition: 0.25s transform ease-in-out;
  display: flex;
  gap: 16px;
  align-items: center;
  transform: scale(0);
}

.visible {
  transform: scale(1);
}

.discountValue {
  font-size: 14px;
  background-color: var(--green-main);
  color: var(--white);
  border-radius: 12px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
}

.oldPrice {
  color: var(--grey);
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  text-decoration: line-through;
  padding-top: 8px;
}

.paymentMethodsWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.promoCodeWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  margin-top: 12px;
}

.input {
  height: 48px;
  border-color: var(--green-main);

  input {
    padding-left: 16px;
    font-size: 14px;
  }
}

.checkPromoCodeButton {
  padding: 0 16px;
  border-radius: 4px;
}

.preloader {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.agreementText {
  text-align: center;
  color: var(--grey);
  font-size: 14px;
  font-weight: 500;

  a {
    color: var(--grey);
  }
}
