.container {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0 auto;
}

.stepItemWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.separator {
  flex-shrink: 0;
  width: 40px;
  height: 1px;
  background-color: var(--grey-separator);
}

.stepItem {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 700;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 1px solid var(--grey-separator);
  color: var(--grey);
}

.completed {
  background-color: var(--green-main);
  color: var(--white);
  border-color: var(--green-main);
}

.active {
  background-color: var(--bg);
  color: var(--green-main);
  border-color: var(--bg);
}
