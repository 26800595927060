.paymentsWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.paymentsTitle {
  color: var(--dark);
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;
}

.paymentsInnerWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 24px;
}

.paymentsPlaceholder {
  color: var(--grey-separator);
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}