@import "../../styles/media-mixin.scss";

.pageContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: calc(var(--header-height) + 48px);

  @include media("<desktop-lg") {
    padding-top: calc(var(--header-height) + 24px);
  }
}

.container {
  margin: 0 auto;
  max-width: var(--content-max-width);
  padding: 0 var(--horizontal-page-padding) var(--horizontal-page-padding);
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @include media("<tablet") {
    gap: 32px;
  }
}