@import "../../styles/media-mixin.scss";

.wrapper {
  width: 100%;
  background-color: var(--white);
  margin-top: auto;
}

.innerWrapper {
  margin: 0 auto;
  width: 100%;
  max-width: var(--content-max-width);
  padding: 24px var(--horizontal-page-padding);
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.topSection {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include media("<tablet-lg") {
    flex-direction: column;
    justify-content: unset;
    align-items: unset;
    gap: 32px;
  }
}

.logoSection {
  display: flex;
  align-items: center;
  gap: 20px;
}

.buttonsSection {
  display: flex;
  align-items: center;
  gap: 8px;

}

.button {
  @include media("<tablet") {
    flex: 1 1 50%;
  }
}

.policiesSection {
  padding: 0 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  //border-top: 1px solid var(--grey-separator);
  border-bottom: 1px solid var(--grey-separator);

  @include media("<tablet-lg") {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 12px;
  }
}

.bottomSection {
  color: var(--grey);
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

.linkItem {
  color: var(--grey);
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-decoration: none;

  @include media("<desktop-lg") {
    font-size: 12px;
  }

}

.separator {
  width: 1px;
  height: 32px;
  background-color: var(--grey-separator);
}

.footerText {
  color: var(--grey);
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}