@import "../../../../../../../../styles/media-mixin.scss";

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;

  @include media("<desktop-lg") {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
}

.title {
  color: var(--dark);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
