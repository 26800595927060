.container {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: 0.25s opacity ease-in-out, 0.25s transform ease-in-out;
  opacity: 0;
  transform: translateY(5px) scale(0.98);
}

.show {
  opacity: 1;
  transform: translateY(0) scale(1);
}