.root {
  overflow: unset;
}

.preloader {
  transition: .25s opacity ease-in-out;
  opacity: 0 !important;
  pointer-events: none;
}

.pageType {
  top: var(--header-height);
}
