.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  background: transparent;
  will-change: background-color;
  z-index: var(--modal-z-index);
  pointer-events: none;

  &.active {
    background: var(--modal-overlay-bg);
    bottom: 0;
    pointer-events: auto;
  }
}

.modal {
  transform: scale(0.95);
  transition: .25s transform ease-in-out;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  will-change: transform;


  &.active {
    transform: scale(1);
  }
}

