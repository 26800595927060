.wrapper {
  overflow-y: scroll;
}

.container {
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.email {
  position: relative;
  display: flex;
  align-items: center;
  gap: 16px;
  height: 56px;
  border-radius: 4px;
  border: 1px solid var(--grey-separator);
  background: var(--white);
  color: var(--dark);
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  padding: 0 24px;
}

.emailIcon {
  color: var(--orange-main);
}

.iconWrapper {
  position: relative;
  flex-shrink: 0;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: var(--green-main);
  border: 2px solid;
  border-color: var(--green-main);
  width: 24px;
  height: 24px;
}

.warning {
  color: var(--orange-main);
  border-color: var(--orange-main);

  &:after {
    content: "Email not confirmed";
    position: absolute;
    top: 50%;
    right: 24px;
    width: max-content;
    opacity: 0;
    transform: translateY(-50%) scale(0);
    transition: 0.25s opacity ease-in-out, 0.25s transform ease-in-out;
    color: currentColor;
    font-weight: 500;
    font-size: 12px;
    border-radius: 4px;
    padding: 4px;
    background-color: var(--bg);
    //box-shadow: 0 0 4px var(--grey-separator);
  }

  &:hover {
    &:after {
      opacity: 1;
      transform: translateY(-50%) scale(1);
    }
  }
}

.actionsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.changePasswordButton {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.logoutButton {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 12px;
  border-radius: 8px;
  border: none;
  background: var(--grey-separator);
  color: var(--dark);
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  transition: 0.25s background-color ease-in-out, 0.25s color ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: var(--green-main-hover);
    color: var(--white);

    svg {
      color: var(--white);
    }
  }

  svg {
    transition: 0.25s color ease-in-out;
    color: var(--grey);
  }
}

.separator {
  margin: 16px 0;
  height: 1px;
  background-color: var(--grey-separator);
}
