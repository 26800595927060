
.selectMenuWrapper {
  position: relative;

}

.openButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  color: var(--grey);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  width: 100%;
  background-color: transparent;
  border: none;
  text-align: left;
  cursor: pointer;

  svg {
    transition: 0.25s transform ease-in-out;
  }
}

.openButtonOpened {
  svg {
    transform: scale(-1);
  }
}

.dropdownMenu {
  position: absolute;
  top: 48px;
  left: 0;
  opacity: 0;
  height: 0;
  width: 100%;
  transition-duration: 0.25s;
  transition-timing-function: ease-in-out;
  transition-property: opacity, height;
  overflow: scroll;
  border: 1px solid var(--grey-separator);
  border-radius: 8px;
  background-color: var(--white);
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.25);
}

.menuOpened {
  opacity: 1;
  height: 400px;
}

.optionButton {
  padding: 8px 12px;
  color: var(--dark);
  background-color: transparent;
  width: 100%;
  border: none;
  text-align: left;
  transition-timing-function: ease-in-out;
  transition-duration: 0.25s;
  transition-property: color, background-color;
  outline: none;

  &:not(.optionButtonActive) {
    cursor: pointer;

    &:hover,
    &:focus {
      color: var(--orange-main);
      background-color: var(--grey-separator);
    }
  }
}

.optionButtonActive {
  pointer-events: none;
  color: var(--orange-main);
  background-color: var(--bg);


}

.searchInputWrapper {
  padding: 8px 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--grey-separator);
  border-bottom: 1px solid var(--grey-separator);
}

.searchInput {
  color: var(--dark);
  flex-grow: 1;
  outline: none;
  border: none;
  font-weight: 500;
  line-height: 22px;
}
