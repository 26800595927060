:root {
  // ----- GREEN ----- //
  --green-main: #005800;
  --green-main-hover: #016F01;
  --green-main-active: #004200;

  // ----- ORANGE ----- //
  --orange-main: #FD6E03;
  --orange-main-hover: #FF872D;
  --orange-main-active: #E16202;

  // ----- RED ----- //
  --red-main: #FF3131;
  --red-hover: #FFE7E7;

  // ----- WHITE ----- //
  --white: #FFFFFF;

  // ----- GREY ----- //
  --grey: #6A728A;
  --grey-separator: #DAE7E1;

  // ----- BG ----- //
  --bg: #F0F5F3;
  --bg-secondary: #F9FBFA;

  // ----- DARK ----- //
  --dark: #07133B
}
