.page {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.layout {
  display: flex;
  flex-direction: column;
  flex: 1;
  transition-timing-function: ease-in-out;
  transition-duration: 0.25s;
  transition-property: opacity, transform;
  opacity: 0;
  transform: translateY(10px) scale(0.98);
}

.show {
  opacity: 1;
  transform: translateY(0) scale(1);
}

.enter {
  opacity: 0;
  transform: translateY(25px);
  z-index: 1;
}

.enter.enterActive {
  will-change: opacity, transform;
  opacity: 1;
  transform: translateY(0);
  transition: opacity 250ms ease-out, transform 300ms ease;
}

.exit {
  opacity: 1;
  transform: translateY(0);
}

.exit.exitActive {
  will-change: opacity, transform;
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 250ms ease-out, transform 300ms ease;
}