@import "../../../../styles/media-mixin.scss";

.container {
  flex: 1;
  border-radius: 8px;
  background: var(--white);
  display: flex;

}

.groupsSection {
  position: relative;
  height: 100%;
  width: 420px;
  border-right: 1px solid var(--grey-separator);
  overflow: hidden;
  border-radius: 8px 0 0 8px;

  @include media("<desktop-lg") {
    width: 354px;
    border: 1px solid var(--grey-separator);
    background-color: var(--bg);
  }

  @include media("<tablet") {
    width: 100%;
    border-color: transparent;
  }
}

.groupsSectionInner {
  position: absolute;
  top: 24px;
  left: 0;
  bottom: 24px;
  right: 12px;
  overflow-y: scroll;
  padding-left: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @include media("<tablet") {
    padding: 0;
    top: 0;
    right: 0;
  }
}

.careerInfoWrapper {
  @include media("<tablet") {
    display: none;
  }
}

.modal {
  margin-bottom: 0 !important;
}