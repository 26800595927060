.container {
  border-radius: 12px;
  background-color: var(--bg);
  background-size: cover;
  background-position: bottom;
  padding: 24px 24px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stepNumber {
  color: var(--grey);
  font-size: 14px;
  font-weight: 700;
  line-height: 28px;
  text-transform: uppercase;
}

.title {
  margin-top: 24px;
  color: var(--dark);
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  line-height: 38px;
}

.description {
  margin-top: 8px;
  color: var(--grey);
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
}

.linkButton {
  margin-top: auto;
}

.priceWrapper {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.oldPrice {
  color: var(--grey);
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  text-decoration: line-through
}

.newPrice {
  color: var(--dark);
  font-size: 40px;
  font-weight: 600;
  line-height: 32px;
}
