@import "../../../styles/media-mixin";

.button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 40px;
  border-radius: 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  transition: 0.25s background-color ease-in-out, 0.25s color ease-in-out, 0.25s border-color ease-in-out;
  white-space: nowrap;
  text-decoration: none;
  border: 1px solid transparent;
  background-color: transparent;
  flex-shrink: 0;

  &:not(.disabled):not(.loading) {
    cursor: pointer;
  }
}

.uppercase {
  text-transform: uppercase;
}

.iconWrapper {
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

.preloaderIconWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 28px;
  height: 28px;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: 0.25s opacity ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.show {
  opacity: 1;
}

.iconBefore {
  margin-right: 12px;
}

.iconAfter {
  margin-left: 12px;
}

.filled-primary {
  position: relative;
  color: var(--white);
  background-color: var(--green-main);

  &:not(.disabled):not(.loading):hover {
    background-color: var(--green-main-hover);
  }

  &:not(.disabled).active {
    background-color: var(--green-main-active);
  }

  //&:after {
  //  position: absolute;
  //  content: "";
  //  left: 0;
  //  bottom: 0;
  //  right: 0;
  //  top: 0;
  //  background-color: transparent;
  //  border-radius: inherit;
  //  mix-blend-mode: overlay;
  //  background-blend-mode: multiply, normal;
  //  transition: background-color .25s ease-in-out;
  //}
  //
  //&:not(&.loading):not(&.disabled) {
  //  &:hover {
  //    &:after {
  //      background-color: rgba(0, 0, 0, .3);
  //    }
  //  }
  //}
  //
}

.filled-secondary {
  position: relative;
  color: var(--white);
  background-color: var(--orange-main);

  &:not(.disabled):not(.loading):hover {
    background-color: var(--orange-main-hover);
  }

  &:not(.disabled).active {
    background-color: var(--orange-main-active);
  }
}

.outline {
  color: var(--grey);
  border-color: var(--grey-separator);

  &:not(.disabled):not(.loading):hover {
    color: var(--green-main);
  }

  &:not(.disabled).active {
    color: var(--green-main-active);
  }
}

.filled-white {
  color: var(--green-main);
  background-color: var(--white);

  &:not(.disabled):not(.loading):hover {
    color: var(--green-main-hover);
  }

  &:not(.disabled).active {
    color: var(--green-main-active);
  }
}

.outfilled {
  color: var(--green-main);
  background-color: transparent;
  padding: 0;
  height: unset !important;

  &:not(.disabled):not(.loading):hover {
    color: var(--green-main-hover);
  }

  &:not(.disabled).active {
    color: var(--green-main-active);
  }
}

.disabled {
  color: var(--grey) !important;
  background-color: var(--grey-separator) !important;
}

.small {
  height: 32px;
}

.medium {
  height: 40px;
}

.large {
  height: 48px;
}

.fullWidth {
  width: 100%;
}

.roundBorder-small {
  border-radius: 4px;
}

.roundBorder-large {
  border-radius: 40px;
}

.loading {
  color: transparent !important;
}
