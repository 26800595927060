.container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  flex: 1;
  transition: 0.25s opacity ease-in-out, 0.25s transform ease-in-out;
  opacity: 0;
  transform: translateY(5px) scale(0.98);
  overflow: auto;
}

.show {
  opacity: 1;
  transform: translateY(0) scale(1);
}

.titleWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.title {
  color: var(--dark);
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 40px;
  text-transform: uppercase;
}

.subtitle {
  color: var(--grey);
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.containerInner {
  display: flex;
  flex-direction: column;
  flex: 1;
}
