.container {
  position: relative;
  border-radius: 80px;
  background: var(--bg);
  width: 160px;
  height: 40px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.25s justify-content ease-in-out, 0.25s outline-color ease-in-out;
  padding: 4px;
  cursor: pointer;
  outline: 2px solid transparent;

  &:focus-visible {
    outline-color: var(--orange-main);
  }
}

.checked {

  .toggle {
    left: 80px;
  }
}

.originCheckbox {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  visibility: hidden
}

.toggle {
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 80px;
  background: var(--orange-main);
  width: 76px;
  height: 32px;
  transition: 0.25s left ease-in-out;
  z-index: 0;
}

.text {
  position: relative;
  z-index: 1;
  display: flex;
  width: 76px;
  justify-content: center;
  align-items: center;
  color: var(--grey);
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  text-transform: uppercase;
  transition: 0.25s color ease-in-out;
}

.textChecked {
  color: var(--white);
}