@import "../../../../styles/media-mixin.scss";

.container {
  padding: 104px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--white);

  @include media("<desktop-lg") {
    align-items: flex-start;
  }

  @include media("<tablet") {
    padding: 80px 0;
  }
}

.title {
  color: var(--dark);
  font-size: 40px;
  font-weight: 500;
  line-height: 56px;
  text-transform: uppercase;
  padding: 0 var(--horizontal-page-padding);

  @include media("<tablet") {
    font-size: 32px;
    line-height: 48px;
  }
}

.description {
  margin-top: 24px;
  color: var(--grey);
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  padding: 0 var(--horizontal-page-padding);
  max-width: calc(480px + (var(--horizontal-page-padding) * 2));
  text-align: center;

  @include media("<desktop-lg") {
    text-align: left;
  }
}

.guideCardsWrapper {
  margin-top: 48px;
  max-width: var(--content-max-width);
  width: 100%;
  overflow: hidden;
}

.guideCardsInnerWrapper {
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(4, minmax(280px, 1fr));
  grid-template-rows: 346px;
  grid-gap: 16px;
  padding: 0 var(--horizontal-page-padding);
  overflow-x: scroll;
}

.modal {
  @include media("<phone-lg") {
    margin-bottom: 0 !important;
  }
}