@import "../../styles/media-mixin.scss";

.pageContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: calc(var(--header-height) + 36px);
  padding-bottom: 24px;
  background-color: var(--bg);

  @include media("<tablet-lg") {
    padding-bottom: 0;
  }


  @include media("<tablet") {
    padding-top: calc(var(--header-height) + 24px);
    overflow: hidden;
  }
}

.container {
  margin: 0 auto;
  max-width: var(--content-max-width);
  padding: 0 var(--horizontal-page-padding);
  flex: 1;
  width: 100%;
}

.containerInner {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 8px 0 8px 8px;
  border-radius: 8px;
  background: var(--white);

  @include media("<tablet-lg") {
    border-radius: 8px 8px 0 0;
    flex-direction: column;
    padding: 2px;
  }
}

.closeIcon {
  position: absolute;
  top: 16px;
  right: 16px;
  color: var(--grey);
  cursor: pointer;
  z-index: 1;

  @include media("<tablet-lg") {
    display: none;
  }
}

.progressSection {
  flex-shrink: 0;
  flex-basis: 288px;
  border-radius: 6px;
  background: var(--bg);
}

.contentSection {
  flex: 1;
  display: flex;
  flex-direction: column;
}