.container {
  border: 1px solid var(--grey-separator);
  border-radius: 8px;
  flex-shrink: 0;
}

.priceWrapper {
  padding: 8px 12px;
  border-bottom: 1px solid var(--grey-separator);
}

.priceValue {
  color: var(--dark);
  font-size: 32px;
  font-weight: 500;
  line-height: 48px;
}

.PriceText {
  color: var(--grey);
  font-weight: 700;
  line-height: 22px;
  text-transform: uppercase;
}