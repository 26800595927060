@import "../../../styles/media-mixin.scss";

.caption {
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;
  border-radius: 80px;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  @include media("<tablet") {
    font-size: 12px;
  }
}

.paddings-s {
  padding: 4px 16px;
}

.paddings-m {
  padding: 8px 20px;
}

.orange {
  color: var(--orange-main);
  background: rgba(253, 110, 3, 0.08);
}

.green {
  color: var(--green-main);
  background-color: var(--bg);
}